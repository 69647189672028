<template>
    <div>

        <v-autocomplete :prepend-icon="icon ? icons.mdiAlphaCCircle : null" :clearable="clearable" hide-details="auto"
            :disabled="disabled" :value="value" v-on:input="handleInput" :filter="customFilter" :items="lista"
            label="Proveedor" item-value="id" item-text="razonSocial" :dense="outlined" :outlined="outlined"
            :loading="cargando" :search-input.sync="search"
            :append-outer-icon="icons.mdiPlusCircleOutline"  @click:append-outer="abrirModal()" :rules="rules">

            <template v-slot:no-data>
                <v-list-item>
                    <v-list-item-title>
                        No hay coincidencia
                    </v-list-item-title>
                </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
                <span>{{ item.tipoDocumento.abreviatura }}-{{ item.documento }} {{ item.razonSocial }}</span>
            </template>
            <template v-slot:item="{ item }">

                <v-list-item-avatar color="primary" size="30" class=" my-2 py-2 font-weight-light white--text">
                    <small>{{ item.razonSocial.charAt(0) }}{{ item.razonSocial.charAt(1) }}</small>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ item.razonSocial }}
                        <br>
                        <small> {{ item.tipoDocumento.abreviatura }}-{{ item.documento }}</small>
                    </v-list-item-title>

                </v-list-item-content>
                <v-list-item-action-text>
                    {{ item.razonComercial }}
                </v-list-item-action-text>

            </template>
        </v-autocomplete>
        <v-dialog v-model="dialog" width="750">
            <Actualizar @GetActualizarLista="GetActualizarLista"></Actualizar>
        </v-dialog>
    </div>

</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlphaCCircle, mdiPlusCircleOutline } from '@mdi/js'
import Actualizar from './Actualizar.vue'
import ProveedorServices from '@/api/servicios/ProveedorServices'
export default {
    components: {
        Actualizar
    },
    props: {
        value: Number,
        jsonRespueta: Boolean,
        disabled: Boolean,
        dense: Boolean,
        outlined: Boolean,
        icon: Boolean,
        todos: Boolean,
        clearable: Boolean,
        rules: Array
    },
    setup(props, contex) {
        const lista = ref([])
        const select = ref(null)
        const cargando = ref(false)
        const dialog = ref(false)
        const search = ref(null)
        const customFilter = (item, queryText, itemText) => {
            const filtro1 = item.documento.toUpperCase()
            const filtro2 = item.razonSocial.toUpperCase()
            const filtro3 = item.razonComercial.toUpperCase()
            const filtro4 = item.telefono.toUpperCase()
            const filtro5 = item.correo.toUpperCase()
            const filtro6 = item.paginaWeb.toUpperCase()
            const searchText = queryText.toUpperCase()

            return filtro1?.indexOf(searchText) > -1 ||
                filtro2?.indexOf(searchText) > -1 ||
                filtro3?.indexOf(searchText) > -1 ||
                filtro4?.indexOf(searchText) > -1 ||
                filtro5?.indexOf(searchText) > -1 ||
                filtro6?.indexOf(searchText) > -1
        }

        const GetActualizarLista = (item) => {

            lista.value.push(item)
            props.value   = item.id
            dialog.value = false
        }
        const abrirModal = () => {
            dialog.value = true
        }
        const cargarDatos = () => {
            if (cargando.value == true)
                return

            lista.value = []
            cargando.value = true
            try {
                setTimeout(() => {
                    ProveedorServices.ConsultarBusqueda({
                        Busqueda: search.value
                    })
                        .then(response => {
                            console.log(response)
                            if (response.data.estatus == true) {
                                lista.value = response.data.datos
                            }
                        })
                        .catch(error => {
                            console.log(error)
                        })
                        .finally(() => {
                            cargando.value = false
                        })
                }, 1000);
            } catch (error) {

                cargando.value = false
            }
        }
        watch(search, () => {
            cargarDatos()
        })
/*
        watch (props,()=>{
            props.inputValue 
        })*/
        const handleInput = (event) => {
      
            
            const valor = lista.value.find(item => (item.id == event))

            if (props.jsonRespueta == true) {
                contex.emit('input',
                    valor == undefined ? null : valor)
            } else {
                contex.emit('input',
                    event)
            }
        }

       
        return {
            icons: {
                mdiAlphaCCircle,
                mdiPlusCircleOutline
            },
            lista,
            cargando,
            dialog,
            abrirModal,
            GetActualizarLista,
            search,
            customFilter,
            handleInput
        }
    },
}
</script>