//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'
class OrdenCompraServices {
    Consultar(
        datos
    ) {
        return http.get("ordenCompra/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }

    Actualizar(
        idUsuario,
        Data
    ) {
        const datos = {
            idUsuario,
            Data
        }
        console.log(datos)
        return http.post("/ordenCompra/actualizar", datos)
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }
}

export default new OrdenCompraServices();